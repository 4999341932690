import React from "react"
import { Container, Row, Col, Button, Image } from "react-bootstrap"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import CUBES from "../../assets/images/cubes.png"
import Header from "../components/Header"

const NotFound = () => (
  <Container fluid className="main-container">
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404</title>
      </Helmet>
    </div>
    <Header headerBackgroundColor="#333333" headerTextMode="light" />
    <Container fluid className="image-container">
      <Image className={"image-background"} src={CUBES} />
    </Container>
    <Container fluid className="first-section d-flex align-items-center">
      <Container className="introduction-container">
        <Row style={{ flex: 1, justifyContent: "center" }}>
          <Col className={"introduction-texts-col col-lg-12 col-10"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "96px",
                }}
              >
                404
              </h1>
              <h2
                style={{ textAlign: "center", fontWeight: "bold" }}
                className="introduction-texts-1"
              >
                Page Not Found
              </h2>

              <h6
                style={{
                  lineHeight: "30px",
                  textAlign: "center",
                  fontSize: 16,
                  width: 250,
                }}
                className="introduction-texts-2"
              >
                The page you looking for might have been removed had its name
                changed or is temporarily unavailable
              </h6>
            </div>
            <Link style={{ textAlign: "center" }} to="/">
              <Button className="button">GO TO HOME</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  </Container>
)

export default NotFound
